import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { Button, Link } from 'gatsby-theme-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles({
    image: {
        borderRadius: '8px',
        width: '100%',
    }
})

const Addon = ({ pageContext: { addon } }) => {
    const classes = useStyles();

    return (
        <Layout>
            <SEO title={`${addon.name} Add-on`} />
            <Box pb={8} pt={16} bgcolor="secondary.dark">
                <Container maxWidth="sm" align="center">
                    {
                        (addon.isNew || addon.isComingSoon) && (
                            <Box pb={2}>
                                <Chip
                                    color={addon.isNew ? 'primary' : undefined}
                                    label={addon.isNew ? 'New' : 'Coming Soon'}
                                />
                            </Box>
                        )
                    }
                    <Box color="primary.light">
                        <Typography color="inherit" variant="h6" component="p">
                            SpeedyDock Add-on
                        </Typography>
                    </Box>
                    <Typography variant="h4" component="h1">
                        { addon.name }
                    </Typography>
                </Container>
            </Box>
            <Box pb={8} pt={8}>
                <Container align="center" maxWidth="sm">
                    <Typography paragraph>
                        {addon.description}
                    </Typography>

                    {
                        addon.photoSrc && (
                            <Box pt={4}>
                                <img className={classes.image} src={addon.photoSrc} alt={addon.name} />
                            </Box>
                        )
                    }

                    <Box pt={8} pb={3}>
                        <Typography variant="h6">
                            Compatibility
                        </Typography>
                    </Box>
                    <Typography paragraph>
                        This add-on is eligible for purchase with our <Link to="/products/dry-stack">Dry Stack Marina Operations</Link> product and our <Link to="/products/boat-club">Boat Club Management</Link> product.
                    </Typography>

                    <Box pt={4}>
                        <Button to="/contact" variant="contained" color="primary" size="large">
                            Contact us to purchase this add-on
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Layout>
    );
};

export default Addon;
